const config = {
  siteName: '母之韵',
  name: 'sm',
  enName: 'SEME',
  title: '母之韵',
  site: '',
  favicon: require('./img/favicon.ico').default,
  description: '母之韵',
  keywords: '母之韵',
  url: 'https://p1.qy2mb.vip',
  // 主题颜色
  // 底部icon 颜色，因为太定制化了 暂时用配置的方法
  themes: {
    primaryColor: '#E7B677',
    footerSVG: {
      defaultBgColor: '#BEBBBA',
      activeBgColor: '#E7B677',
      defaultIconColor: '#1b1b1b',
      activeIconColor: '#fff'
    }
  }
}
export default config
